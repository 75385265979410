var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('p',{staticClass:"auth-title"},[_vm._v("Авторизация")]),(_vm.authError)?_c('a-alert',{staticClass:"error",attrs:{"type":"error","message":_vm.authError}}):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'userName',
        {
          rules: [
            {
              required: true,
              message: 'Введите имя пользвоателя или email!',
            } ],
        } ]),expression:"[\n        'userName',\n        {\n          rules: [\n            {\n              required: true,\n              message: 'Введите имя пользвоателя или email!',\n            },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"Имя пользователя или email"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password']),expression:"['password']"}],attrs:{"type":"password","placeholder":"Пароль"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[(false)?_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'remember',
        {
          valuePropName: 'checked',
          initialValue: true,
        } ]),expression:"[\n        'remember',\n        {\n          valuePropName: 'checked',\n          initialValue: true,\n        },\n      ]"}]},[_vm._v(" Запомнить меня ")]):_vm._e(),_c('a',{staticClass:"login-form-forgot",on:{"click":_vm.recoveryPass}},[_vm._v("Забыл пароль")]),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Войти ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }