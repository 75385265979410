<template>
  <div class="login-page">
    <div class="login-page-logo-wrap">
      <div class="login-page-logo-image"></div>
    </div>
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from "@/components/login/LoginForm"
export default {
  name: "Login",
  components: {
    LoginForm,
  },
}
</script>

<style lang="scss">
.login-page {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 5rem;
  height: 100vh;

  .login-page-logo-wrap {
    width: 100%;
    height: 190px;
    padding: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;

    .login-page-logo-image {
      background-image: url("../assets/images/JLR_Logo_Lockup_Horiz_180mm_RGB.svg");
      width: 230px;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
</style>
