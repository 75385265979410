<template>
  <a-form
    id="components-form-demo-normal-login"
    :form="form"
    class="login-form"
    @submit="handleSubmit"
  >
    <p class="auth-title">Авторизация</p>

    <a-alert class="error" v-if="authError" type="error" :message="authError" />

    <a-form-item>
      <a-input
        v-decorator="[
          'userName',
          {
            rules: [
              {
                required: true,
                message: 'Введите имя пользвоателя или email!',
              },
            ],
          },
        ]"
        placeholder="Имя пользователя или email"
      >
        <a-icon slot="prefix" type="user" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input v-decorator="['password']" type="password" placeholder="Пароль">
        <a-icon slot="prefix" type="lock" style="color: rgba(0, 0, 0, 0.25)" />
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-checkbox
        v-if="false"
        v-decorator="[
          'remember',
          {
            valuePropName: 'checked',
            initialValue: true,
          },
        ]"
      >
        Запомнить меня
      </a-checkbox>
      <a class="login-form-forgot" @click="recoveryPass">Забыл пароль</a>
      <a-button type="primary" html-type="submit" class="login-form-button">
        Войти
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { notification } from "ant-design-vue"

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "normal_login",
    })
  },
  data: () => ({
    email: "",
    password: "",
  }),
  computed: mapGetters({
    authError: "auth/authError",
    userRole: "auth/userRole",
    user: "auth/user",
    rolesRoutes: "auth/rolesHomeRoute",
  }),
  methods: {
    async recoveryPass(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = { email: values.userName }
          this.passwordRecovery(params).then(response => {
            if (response.data.message) {
              notification.open({
                message: "Восстановление пароля",
                description: response.data.message,
                duration: 0,
                style: {
                  width: "600px",
                  marginLeft: `${100 - 600}px`,
                },
              })
            }
          })
        }
      })
    },
    ...mapActions({
      login: "auth/login",
      passwordRecovery: "auth/passwordRecovery",
    }),
    async handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.login({
            login: values.userName,
            password: values.password,
          }).then(() => {

            const script = document.createElement('script');
            script.id="quiz-widget"
            script.src = "https://litenps.smartconsulting.pro/quizjs.min.js?email="+this.user.email+"&id=2";
            document.head.appendChild(script);
            if(document.getElementById("quiz-start"))
              document.getElementById("quiz-start").style.display = "flex"

            const roleHomeRoute = this.rolesRoutes[this.userRole]
            console.info("user role:", this.userRole)
            if (this.userRole !== null) {
              this.$router.push(roleHomeRoute)
            } else {
              this.$router.push("/403")
            }
            //this.$router.push(this.rolesRoutes[role] ?? "/")
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.error {
  margin-bottom: 20px;
}

.auth-title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-family: $fontf-main;
  font-weight: 500;
}
</style>
